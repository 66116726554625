<template>
  <div>
    <v-row dense no-gutters>
      <v-col cols="12">
        <h3>Turmas</h3>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            <v-row dense align="start">
              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  label="Buscar"
                  placeholder="Digite um valor para filtrar"
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  outlined
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="level"
                  :items="levels"
                  :disabled="disabled"
                  label="Nível"
                  prepend-inner-icon="school"
                  no-data-text="Cotas inexistentes"
                >
                </v-select>
              </v-col>

              <v-col cols="1">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  label="Semestre"
                  placeholder="..."
                  v-model="semester"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  label="Ano"
                  placeholder="..."
                  v-model="year"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  outlined
                  dense
                  color="green"
                  class="mt-0 mb-1"
                  height="40"
                  @click="getAsyncClassesByLevelAndYearAndSemester()"
                  >Listar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-bind:headers="headers"
          v-bind:items="items"
          :search="search"
          item-key="id"
          :items-per-page="classes.length"
          hide-default-footer
          :loading="disabled"
          loading-text="Buscando turmas"
          no-data-text="Não foram encontrados turmas"
        >
          <template v-slot:item="props">
            <tr>
              <td class="text-xs-left">{{ props.item.subjectCode }}</td>
              <td class="text-xs-left">{{ props.item.classCode }}</td>
              <td class="text-xs-left">{{ props.item.subjectName }}</td>
              <td class="text-xs-left">{{ props.item.students }}</td>
              <td class="text-xs-left">{{ props.item.semester }}</td>
              <td class="text-xs-left">{{ props.item.year }}</td>
              <td class="text-xs-left">
                <p v-for="p in props.item.professors" :key="p.id">
                  <v-icon small>school</v-icon> {{ p.name }}
                </p>
              </td>
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      text
                      x-small
                      color="amber darken-3"
                      v-clipboard:success="clipboardSuccessHandler"
                      v-clipboard:error="clipboardErrorHandler"
                      v-clipboard:copy="props.item.emails"
                      ><v-icon color="amber">mail</v-icon>
                      {{ props.item.countEmails }}</v-btn
                    >
                  </template>
                  <span
                    >Copiar e-mails acadêmicos da turma
                    {{ props.item.subjectCode }}-{{
                      props.item.classCode
                    }}</span
                  >
                </v-tooltip>
              </td>
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      x-small
                      color="green"
                      @click="openEnrollments(props.item)"
                      ><v-icon small >search</v-icon></v-btn
                    >
                  </template>
                  <span
                    >Visualizar a turma {{ props.item.subjectCode }}-{{
                      props.item.classCode
                    }}</span
                  >
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="1500">
      <Enrollments
        :classTarget="classTarget"
        v-on:close-dialog="closeEnrollments()"
      ></Enrollments>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Enrollments from "@/components/common/enrollments/Enrollments";

export default {
  components: { Enrollments },
  data() {
    return {
      headers: [
        {
          sortable: true,
          text: "Disciplina",
          value: "subjectCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Turma",
          value: "classCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Disciplina",
          value: "subjectName",
          align: "left",
        },
        {
          sortable: true,
          text: "Alunos",
          value: "students",
          align: "left",
        },
        {
          sortable: false,
          text: "Semestre",
          value: "semester",
          align: "left",
        },
        {
          sortable: false,
          text: "Ano",
          value: "year",
          align: "left",
        },
        {
          sortable: true,
          text: "Professores",
          value: "professors",
          align: "left",
        },
        {
          sortable: false,
          text: "E-mail",
          value: "",
          align: "left",
        },
        {
          sortable: false,
          text: "Consultar",
          value: "",
          align: "left",
        },
      ],
      items: [],
      search: "",
      level: {
        id: "P",
        name: "Pós-Graduação",
      },
      disabled: false,
      levels: [
        {
          id: "P",
          name: "Pós-Graduação",
        },
        {
          id: "G",
          name: "Graduação",
        },
      ],
      year: new Date().getFullYear(),
      semester: new Date().getMonth() >= 7 ? 2 : 1,
      dialog: false,
      classTarget: {},
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
    };
  },

  created() {
    this.items = [];
    this.getAsyncCurrentClassesByLevel(this.level.id);
  },

  computed: {
    ...mapState("classes", ["classes"]),
  },

  watch: {
    classes(newValue) {
      this.populateItems(newValue);
    },
    level(newValue) {
      this.year = new Date().getFullYear();
      this.semester = new Date().getMonth() >= 7 ? 2 : 1;
      this.getAsyncCurrentClassesByLevel(newValue.id);
    },
  },

  methods: {
    ...mapActions("classes", [
      "ActionGetCurrentClassesByLevel",
      "ActionGetClassesByLevelAndYearAndSemester",
    ]),

    async getAsyncCurrentClassesByLevel(level) {
      this.disabled = true;
      this.items = [];
      try {
        await this.ActionGetCurrentClassesByLevel(level);
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    async getAsyncClassesByLevelAndYearAndSemester() {
      this.items = [];
      let payload = {
        level: this.level.id,
        year: this.year,
        semester: this.semester,
      };
      try {
        await this.ActionGetClassesByLevelAndYearAndSemester(payload);
      } catch (err) {
        console.log(err);
      }
    },

    populateItems(payload) {
      if (payload.length > 0) {
        payload.map((c) => {
          if (c.students > 0) {
            this.items.push({
              id: c.id,
              year: c.year,
              emails: c.emails,
              classCode: c.code,
              semester: c.semester,
              students: c.students,
              countEmails: c.countEmails,
              subjectName: c.subject.name,
              subjectCode: c.subject.code,
              professors: c.professors,
            });
          }
        });
      }
    },

    clipboardSuccessHandler() {
      this.snackbar = true;
      this.snackBarMessage = "Emails copiados para a área de transferência!";
    },

    clipboardErrorHandler() {
      this.snackbar = true;
      this.snackBarMessage =
        "Erro ao copiar emails para a área de transferência!";
    },

    copyEmails() {},

    openEnrollments(target) {
      this.dialog = true;
      this.classTarget = target;
    },

    closeEnrollments() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>