var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Turmas")])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "start" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Buscar",
                                  placeholder: "Digite um valor para filtrar"
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "return-object": "",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.levels,
                                  disabled: _vm.disabled,
                                  label: "Nível",
                                  "prepend-inner-icon": "school",
                                  "no-data-text": "Cotas inexistentes"
                                },
                                model: {
                                  value: _vm.level,
                                  callback: function($$v) {
                                    _vm.level = $$v
                                  },
                                  expression: "level"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  type: "number",
                                  label: "Semestre",
                                  placeholder: "..."
                                },
                                model: {
                                  value: _vm.semester,
                                  callback: function($$v) {
                                    _vm.semester = $$v
                                  },
                                  expression: "semester"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  type: "number",
                                  label: "Ano",
                                  placeholder: "..."
                                },
                                model: {
                                  value: _vm.year,
                                  callback: function($$v) {
                                    _vm.year = $$v
                                  },
                                  expression: "year"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-0 mb-1",
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    color: "green",
                                    height: "40"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.getAsyncClassesByLevelAndYearAndSemester()
                                    }
                                  }
                                },
                                [_vm._v("Listar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  search: _vm.search,
                  "item-key": "id",
                  "items-per-page": _vm.classes.length,
                  "hide-default-footer": "",
                  loading: _vm.disabled,
                  "loading-text": "Buscando turmas",
                  "no-data-text": "Não foram encontrados turmas"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.subjectCode))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.classCode))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.subjectName))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.students))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.semester))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.year))
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            _vm._l(props.item.professors, function(p) {
                              return _c(
                                "p",
                                { key: p.id },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("school")
                                  ]),
                                  _vm._v(" " + _vm._s(p.name) + " ")
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:success",
                                                        value:
                                                          _vm.clipboardSuccessHandler,
                                                        expression:
                                                          "clipboardSuccessHandler",
                                                        arg: "success"
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:error",
                                                        value:
                                                          _vm.clipboardErrorHandler,
                                                        expression:
                                                          "clipboardErrorHandler",
                                                        arg: "error"
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:copy",
                                                        value:
                                                          props.item.emails,
                                                        expression:
                                                          "props.item.emails",
                                                        arg: "copy"
                                                      }
                                                    ],
                                                    attrs: {
                                                      text: "",
                                                      "x-small": "",
                                                      color: "amber darken-3"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "amber" } },
                                                  [_vm._v("mail")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.item.countEmails
                                                    )
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Copiar e-mails acadêmicos da turma " +
                                        _vm._s(props.item.subjectCode) +
                                        "-" +
                                        _vm._s(props.item.classCode)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      outlined: "",
                                                      "x-small": "",
                                                      color: "green"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEnrollments(
                                                          props.item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("search")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Visualizar a turma " +
                                        _vm._s(props.item.subjectCode) +
                                        "-" +
                                        _vm._s(props.item.classCode)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("Enrollments", {
            attrs: { classTarget: _vm.classTarget },
            on: {
              "close-dialog": function($event) {
                return _vm.closeEnrollments()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }