var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pt-5",
      attrs: { outlined: "", tile: "" },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeDialog()
        }
      }
    },
    [
      _c("v-card-subtitle", [
        _c("div", [
          _c("strong", [
            _vm._v(
              _vm._s(_vm.classTarget.subjectCode) +
                " - " +
                _vm._s(_vm.classTarget.subjectName)
            )
          ])
        ]),
        _c("div", [
          _c("strong", [
            _vm._v(
              "Turma: " +
                _vm._s(_vm.classTarget.classCode) +
                " - 0" +
                _vm._s(_vm.classTarget.semester) +
                "/" +
                _vm._s(_vm.classTarget.year)
            )
          ])
        ]),
        _c("div", [
          _c("strong", [
            _vm._v("Matriculados: " + _vm._s(_vm.activeEnrollments))
          ])
        ]),
        _vm.classTarget.professors.length > 0
          ? _c("div", [
              _vm.classTarget.professors.length > 1
                ? _c("div", [
                    _c(
                      "strong",
                      [
                        _vm._v("Professores: "),
                        _vm._l(_vm.classTarget.professors, function(p, i) {
                          return _c("span", { key: i }, [
                            i == 0
                              ? _c("span", [_vm._v(_vm._s(p.name) + " - ")])
                              : _c("span", [_vm._v(_vm._s(p.name))])
                          ])
                        })
                      ],
                      2
                    )
                  ])
                : _c("div", [
                    _c("strong", [
                      _vm._v("Professor(a): "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.classTarget.professors[0].name))
                      ])
                    ])
                  ])
            ])
          : _vm._e(),
        _c("hr")
      ]),
      _c(
        "v-card-text",
        { staticClass: "justify-center" },
        [
          _c("v-text-field", {
            staticClass: "ma-0",
            attrs: {
              outlined: "",
              dense: "",
              "prepend-inner-icon": "school",
              clearable: "",
              label: "Buscar",
              placeholder: "buscar aluno..."
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("v-data-table", {
            attrs: {
              loading: _vm.disabled,
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "item-key": "id",
              "items-per-page": _vm.enrollments.length,
              "hide-default-footer": "",
              "loading-text": "Buscando turmas",
              "no-data-text": "Não foram encontrados turmas"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.academicCode))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.studentName))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.academicEmail))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.email))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.courseName))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                outlined: "",
                                small: "",
                                dark: "",
                                color: props.item.color
                              }
                            },
                            [_vm._v(_vm._s(props.item.removed))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                color: "green",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showStudent(props.item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("search")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "justify-center mb-10" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "green" },
              on: {
                click: function($event) {
                  return _vm.closeDialog()
                }
              }
            },
            [_vm._v("VOLTAR")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }