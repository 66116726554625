<template>
  <v-card outlined tile @keypress.esc="closeDialog()" class="pt-5">
    <v-card-subtitle>
      <div>
        <strong
          >{{ classTarget.subjectCode }} - {{ classTarget.subjectName }}</strong
        >
      </div>
      <div>
        <strong
          >Turma: {{ classTarget.classCode }} - 0{{ classTarget.semester }}/{{
            classTarget.year
          }}</strong
        >
      </div>
      <div>
        <strong>Matriculados: {{ activeEnrollments }}</strong>
      </div>
      <div v-if="classTarget.professors.length > 0">
        <div v-if="classTarget.professors.length > 1">
          <strong
            >Professores:
            <span v-for="(p, i) in classTarget.professors" :key="i"
              ><span v-if="i == 0">{{ p.name }} - </span
              ><span v-else>{{ p.name }}</span></span
            ></strong
          >
        </div>
        <div v-else>
          <strong
            >Professor(a):
            <span>{{ classTarget.professors[0].name }}</span></strong
          >
        </div>
      </div>

      <hr />
    </v-card-subtitle>
    <v-card-text class="justify-center">
      <v-text-field
        outlined
        dense
        prepend-inner-icon="school"
        clearable
        class="ma-0"
        label="Buscar"
        placeholder="buscar aluno..."
        v-model="search"
      ></v-text-field>
      <v-data-table
        v-bind:loading="disabled"
        v-bind:headers="headers"
        v-bind:items="items"
        :search="search"
        item-key="id"
        :items-per-page="enrollments.length"
        hide-default-footer
        loading-text="Buscando turmas"
        no-data-text="Não foram encontrados turmas"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.studentName }}</td>
            <td class="text-xs-left">{{ props.item.academicEmail }}</td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="text-xs-left">{{ props.item.courseName }}</td>
            <td class="text-xs-left">
              <v-chip outlined small dark :color="props.item.color">{{
                props.item.removed
              }}</v-chip>
            </td>
            <td class="text-xs-left">
              <v-btn
                x-small
                color="green"
                outlined
                @click="showStudent(props.item)"
                ><v-icon small>search</v-icon></v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="justify-center mb-10">
      <v-btn outlined color="green" @click="closeDialog()">VOLTAR</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Enrollments",
  props: ["classTarget"],
  data() {
    return {
      headers: [
        {
          sortable: true,
          text: "RA",
          value: "academicCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Aluno",
          value: "studentName",
          align: "left",
        },
        {
          sortable: true,
          text: "Email Acadêmico",
          value: "academicEmail",
          align: "left",
        },
        {
          sortable: true,
          text: "Email Pessoal",
          value: "email",
          align: "left",
        },
        {
          sortable: true,
          text: "Curso",
          value: "courseName",
          align: "left",
        },
        {
          sortable: true,
          text: "Situação",
          value: "removed",
          align: "left",
        },
        {
          sortable: false,
          text: "Consultar",
          value: "search",
          align: "left",
        },
      ],
      items: [],
      search: "",
      emails: "sadfsdafsf",
      disabled: false,
      activeEnrollments: 0,
    };
  },

  created() {
    this.getAsyncEnrollmentsByClassId(this.classTarget.id);
  },

  computed: {
    ...mapState("enrollments", ["enrollments"]),
  },

  watch: {
    enrollments(newValue) {
      this.populateItems(newValue);
    },
    classTarget(newValue) {
      this.getAsyncEnrollmentsByClassId(newValue.id);
    },
  },

  methods: {
    ...mapActions("enrollments", ["ActionGetEnrollmentsByClassId"]),

    async getAsyncEnrollmentsByClassId() {
      try {
        this.disabled = true;
        await this.ActionGetEnrollmentsByClassId(this.classTarget.id);
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    getColor(removed) {
      return removed == true ? "red" : "green";
    },

    getSituation(removed) {
      return removed == true ? "Cancelada" : "Ativa";
    },

    populateItems(payload) {
      this.items = [];
      this.activeEnrollments = 0;
      this.emails = "";
      if (payload.length > 0) {
        payload.map((c) => {
          if (c.student.academicEmail) {
            if (this.emails == "") this.emails = c.student.academicEmail;
            else this.emails = this.emails + ", " + c.student.academicEmail;
          }

          if (c.removed == false) this.activeEnrollments++;

          this.items.push({
            id: c.id,
            removed: this.getSituation(c.removed),
            color: this.getColor(c.removed),
            academicCode: c.student.academicCode,
            studentName: c.student.name,
            academicEmail: c.student.academicEmail,
            email: c.student.email,
            courseName: c.course.name,
          });
        });
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    clipboardSuccessHandler({ value }) {
      console.log(value);
      this.snackbar = true;
      this.snackBarMessage = "Emails copiados para a área de transferência!";
    },

    clipboardErrorHandler() {
      this.snackbar = true;
      this.snackBarMessage =
        "Erro ao copiar emails para a área de transferência!";
    },

    showStudent(student) {
      this.$router.push({
        name: "Student",
        params: { academic_code: student.academicCode },
      });
    },
  },
};
</script>

<style scoped>
</style>